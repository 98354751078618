import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Index",
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/index.html",
    name: "IndexHtml",
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/indexPath",
    name: "IndexPath",
    component: () => import("../views/Index.vue"),
  },
  {
    path: "/indexList",
    name: "IndexList",
    component: () => import("../views/indexList.vue"),
  },
  {
    path: "/info",
    name: "Info",
    component: () => import("../views/info.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/about.vue"),
  },
  {
    path: "/cooperation",
    name: "Cooperation",
    component: () => import("../views/cooperation.vue"),
  },
  {
    path: "/concat",
    name: "Concat",
    component: () => import("../views/concat.vue"),
  },
  {
    path: "/link",
    name: "Link",
    component: () => import("../views/link.vue"),
  },
  {
    path: "/rule",
    name: "Rule",
    component: () => import("../views/rule.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
